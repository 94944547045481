import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="A.I.D" link="https://github.com/autoai-org/aid" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Extensible Open Source MLOps System
    </ProjectCard>
    <ProjectCard title="GPU Meter" link="https://github.com/autoai-incubator/powermeter" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
GPU Power Meter
    </ProjectCard>
    <ProjectCard title="CVTron" link="https://github.com/autoai-incubator/powermeter" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Out-of-Box Computer Vision Library
    </ProjectCard>
    <ProjectCard title="View All" link="https://github.com/autoai-org" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
All other projects
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      